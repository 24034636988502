import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface RouteState {
  [key: string]: any
}

const useRouteState = <T extends RouteState>(initialState: T) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ state, setState ] = useState<T>(() => {
    const routeState = location.state as T;
    return routeState ? routeState : initialState;
  });

  useEffect(() => {
    if (location.state) {
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [ location, navigate ]);

  return [ state, setState ] as const;
};

export default useRouteState;
