import React, { FC, useState } from 'react';
import {
  Stack,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Box,
} from '@mechis/elements';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useMembershipsQuery,
} from '@state/mechis-backend/generated/schema';
import { IProps } from './types';
import { useTranslation } from 'react-i18next';
import { DeviceListingWithSelection } from '@mechis/sections/DeviceListingWithSelection/DeviceListingWithSelection';

const SuspendDevicesModal: FC<IProps> = ({ membershipToSwitchId, isOpen, setIsOpen, handleActivate }) => {
  const { t } = useTranslation();
  const { data: membershipListData } = useMembershipsQuery();
  const [ selectedDeviceIds, setSelectedDeviceIds ] = useState<number[]>([]);
  const membershipToSwitch = membershipListData?.memberships?.membershipsDetail
    .find((membership) => membership?.id === membershipToSwitchId);

  return (
    <Dialog
      onClose={() => setIsOpen(false)}
      open={isOpen}
      fullScreen
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#f6f6f6',
          textAlign: 'center',
        },
      }}
    >
      <CancelIcon
        onClick={() => setIsOpen(false)}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '16px',
          color: 'lightgrey',
        }}
      />
      <DialogContent>
        <Typography
          variant="h5"
          textAlign="center"
          mb={1}
          mt={1}
        >
          {t('suspendedDevicesModal.title')}
        </Typography>
        <Typography
          variant="h5"
          textAlign="center"
          mb={1}
          mt={1}
        >
          {t(
            'suspendedDevicesModal.selected',
            { selectedCount: selectedDeviceIds.length, totalToSelect: membershipToSwitch?.deviceCountMembership ?? 0 }
          )}
        </Typography>
        <Box p={2}>
          <div style={{maxHeight: '70vh'}}>
            <DeviceListingWithSelection
              addSelectedDevice={(id: number) => {
                if (selectedDeviceIds.includes(id)) {
                  setSelectedDeviceIds(selectedDeviceIds.filter((deviceId) => deviceId !== id));
                } else {
                  if (selectedDeviceIds.length < (membershipToSwitch?.deviceCountMembership ?? 0)) {
                    setSelectedDeviceIds([ ...selectedDeviceIds, id ]);
                  }
                }
              }}
              isInModal
              selectedDeviceIds={selectedDeviceIds}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          sx={{
            width: '100%',
            pr: 2,
            pl: 2,
          }}
          direction="row" 
          justifyContent="space-around"
        >
          <Button variant="text" onClick={() => setIsOpen(false)}>
            {t('suspendedDevicesModal.back')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              //TODO call suspendAllWithoutThoseIds  and activate
              handleActivate(Number(membershipToSwitchId), selectedDeviceIds);
            }}
          >
            {t('suspendedDevicesModal.continue')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SuspendDevicesModal;
