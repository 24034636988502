import React from 'react';
import { useAttachments } from '@mechis/sections/Attachments';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mechis/elements';
import AttachmentsEventItem from './AttachmentsEventItem';

const AttachmentsConfirmDeleteModal = () => {
  const {
    isDeleteIntent,
    setIsDeleteIntent,
    onDeleteAttachment,
  } = useAttachments();
  const EVENT_IDS = isDeleteIntent?.eventIds ? isDeleteIntent.eventIds : false;
  
  return (
    <Dialog open={isDeleteIntent ? true : false} PaperProps={{ sx: { backgroundColor: '#f6f6f6' } }}>
      <DialogContent sx={{ textAlign: 'center', padding: 2 }}>
        <Typography variant="subtitle2">Opravdu si přejete smazat soubor?</Typography>
        <Typography><small>{isDeleteIntent?.originalName}</small></Typography>
        {EVENT_IDS && (
          <Box mt={2}>
            <Typography variant="subtitle2">který se nachází v těchto eventech</Typography>
            <Stack spacing={1} pt={1}>
              {EVENT_IDS.map((item: string) => <AttachmentsEventItem key={item} id={item} />)}
            </Stack>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ px: 4 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setIsDeleteIntent(null)}
          >
            Ne
          </Button>
          <Button
            variant="contained"
            color="error"
            size="medium"
            onClick={() => {
              if (isDeleteIntent) onDeleteAttachment(isDeleteIntent);
            }}
          >
            Ano
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentsConfirmDeleteModal;
