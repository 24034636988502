import React, { useEffect } from 'react';
import { 
  Typography,
  Paper,
  Box,
  Stack,
  IconButton,
} from '@mechis/elements';
import Expense from '@mechis/blocks/Expense';
import { useTranslation } from 'react-i18next';
import LoupeIcon from '@mui/icons-material/Loupe';
import NumberFormat from 'react-number-format';
import TagFilters from '@mechis/sections/Selections/TagFilters';
import _ from 'lodash';
import { IExpense, ITagOption } from '@mechis/sections/Selections/TagFilters/types';
import useExpense from '@hooks/useExpense';
import { IPrice } from '@state/models/newExpense/types';
import { IProps } from '@mechis/sections/Expenses/types';

const Expenses: React.FC<IProps> = ({ existedExpensesInfo, disablePreselected }) => {
  const { t } = useTranslation();

  const { 
    expenses,
    setExpenses,
    prices,
    setPrices,
    priceTotal,
    setPriceTotal,
  } = useExpense(existedExpensesInfo);

  const onDelete = (tagId: string) => {
    const newValue = _.filter(expenses, (item) => {
      return item.tagId !== tagId;
    });
    setExpenses(newValue);
  };

  useEffect(() => {
    if (prices) {
      const totalPrice = prices.reduce((acc, obj) => acc + obj.price, 0);
      setPriceTotal(totalPrice);
    }
  }, [ prices ]);

  useEffect(() => {
    if (expenses.length > 0) {
      const pricesMap = expenses?.map((expense: IExpense) => {
        return {
          tagId: expense.tagId,
          price: getPriceByTag(expense.tagId) ?? 0,
        };
      });

      setPrices(pricesMap);
    }
  }, [ expenses ]);

  const getPriceByTag = (tagId: string) => _.filter(prices, { tagId })[0]?.price;
  
  const updatePriceByTag = (tagId: string, price: number) => {
    if (prices) {
      const indexToUpdate = prices?.findIndex((item: IPrice) => item.tagId === tagId);
      if (indexToUpdate !== -1) {
        const updatedPrices = [ ...prices ];
        updatedPrices[indexToUpdate] = {
          ...updatedPrices[indexToUpdate],
          price,
        };
        setPrices(updatedPrices);
      }
    }
  };

  return (
    <Paper>
      <Box p={2}>
        <Paper variant="outlined">
          <Box p={2} pt={4}>
            <Stack spacing={2.5}>
              {expenses?.map((expense: ITagOption, index) => {
                return (
                  <Expense
                    key={`expense-${index}-${expense.tagId}`}
                    price={getPriceByTag(expense.tagId)}
                    setPrice={(price) => updatePriceByTag(expense.tagId, price)}
                    tagId={expense.tagId}
                    onDelete={expense.tagId !== 'tag_other' ? () => onDelete(expense.tagId) : undefined}
                    title={t(`tagManager.id.${expense.tagId}`)}
                    tagCategory={expense.tagCategory.name}
                  />
                );
              })}
              <TagFilters
                preselectedTags={disablePreselected ? undefined : [ 'tag_other' ]}
                localTags={expenses}
                setLocalTags={setExpenses}
                customControl={
                  <Stack direction="row-reverse" spacing={2} alignItems="center">
                    <IconButton 
                      color="secondary"
                      size="small"
                    >
                      <LoupeIcon />
                    </IconButton>
                    <Typography color="secondary">
                      <small>
                        <strong>
                          {t('addExpense.chooseTags')}
                        </strong>
                      </small>
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
          </Box>
        </Paper>
        <Stack 
          direction="row" 
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <Box pl={2} />
          <Box pr={2}>
            <Typography
              variant="sectionHeadline"
              align="right"
              sx={{ 
                textTransform: 'uppercase',
                display: 'block',
                mb: (theme) => theme.spacing(-1.5),
              }}
            >
              {t('addExpense.priceTotal')}
            </Typography>
            <Typography variant="h1" align="right">
              <NumberFormat
                value={priceTotal}
                suffix={` ${t('addExpense.currency')}`}
                displayType="text"
                thousandSeparator={' '}
              />
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};

export default Expenses;
