import React, { useEffect, useState } from 'react';
import {
  AttachmentAllowedType,
  useAttachments,
} from '@mechis/sections/Attachments';
import { Box, Button, Skeleton, Stack } from '@mechis/elements';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Lightbox from 'yet-another-react-lightbox';
import QuoteBlock from '@mechis/blocks/QuoteBlock';
import { useTranslation } from 'react-i18next';
import AttachmentsWidgetItem from '../../components/AttachmentsWidgetItem';
import { AttachmentsLightboxItem } from '../../components';
import useTech from '@hooks/useTech';
import { useNavigate } from 'react-router-dom';

const ATTACHMENTS_WIDGET_LIMIT = 6;
const AttachmentsWidget = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useTech();
  const { attachments, loadingAttachments, setAttachments, loadAttachments } = useAttachments();

  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);

  useEffect(() => {
    setAttachments([]);
    loadAttachments();

    return () => {
      setAttachments([]);
    };
  }, []);

  if (loadingAttachments) {
    return (
      <Stack spacing={1} direction={'row'} justifyContent={'space-around'}>
        <Skeleton variant="rounded" width={100} height={100} />
        <Skeleton variant="rounded" width={100} height={100} />
        <Skeleton variant="rounded" width={100} height={100} />
      </Stack>
    );
  }

  if (attachments && attachments.length === 0) {
    return (
      <Stack mt={6} spacing={4} alignItems="center">
        <QuoteBlock>{t('components.attachmentNoData.forDevice')}</QuoteBlock>
      </Stack>
    );
  }

  const slicedAttachments = (attachments.slice(0, ATTACHMENTS_WIDGET_LIMIT) ?? []);
  const imageSlides = slicedAttachments
    .filter((attachment) => attachment?.mimeType?.startsWith('image/'))
    .map((attachment) => ({ 
      src: attachment.storageName || '',
      width: 1200,
      height: 800,
    }));

  const onClick = (storageName: string) => {
    if (slicedAttachments) {
      setClickedImageIndex(
        slicedAttachments
          .filter((attachment) => attachment?.mimeType?.startsWith('image/'))
          .findIndex((attachment) => attachment.storageName === storageName)
      );
      setLightBoxOpened(true);
    }
  };

  return (
    <>
      <Stack direction={'row'} spacing={1} overflow={'auto'}>
        {slicedAttachments.map((file, index) => {
          return (
            <AttachmentsWidgetItem
              key={index}
              src={file.storageName || ''}
              alt={file.originalName || ''}
              type={file.mimeType as AttachmentAllowedType}
              name={file.originalName || ''}
              onClick={() => onClick(file.storageName || '')}
            />
          );
        })}
        <Lightbox
          open={lightBoxOpened}
          close={() => setLightBoxOpened(false)}
          index={clickedImageIndex}
          slides={imageSlides}
          render={{ slide: ({ slide }) => <AttachmentsLightboxItem storageName={slide?.src || ''} /> }}
          plugins={[ Zoom ]}
          zoom={{ maxZoomPixelRatio: 3, scrollToZoom: true }}
        />
      </Stack>
      <Box textAlign="right" mt={1}>
        <Button
          color="secondary"
          size="small"
          variant="contained"
          onClick={() => navigate(`/app/${techId}/tech-detail/attachments`)}
        >
            Více Příloh
        </Button>
      </Box>
    </>
  );
};

export default AttachmentsWidget;
