import React, { useState, useEffect, FC } from 'react';
import { 
  Box,
  Stack,
  Typography,
  Dialog,
  DialogContent,
} from '@mechis/elements';
import useHeader from '@hooks/useHeader';
import AccountVariant from '@mechis/blocks/AccountVariant';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  GetUserActiveMembershipIdDocument,
  UserDataQuoteDocument,
  DevicesDocument as DeviceList,
  useSetNewTypeOfMembershipMutation,
  useGetUserActiveMembershipIdQuery,
  useMembershipsQuery,
  UserDocument,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useLocalStorage } from 'react-use';
import { USER_ID } from '@configurations/constants/app';
import useNavigate from '@hooks/useNavigate';
import ChangeMembershipInfoModal from '../ChangeMembershipInfoModal';
import { MEMBERSHIP_STARTER_ID } from '@configurations/constants/membership';
import SuspendDevicesModal from '../SuspendDevicesModal';
import { IProps } from './types';

const Membership: FC<IProps> = ({ setNoCreditOpen }) => {
  useHeader({ headline: 'appBar.headline.membership' });
  const { data: memberShipData } = useMembershipsQuery();
  const accountsData = memberShipData?.memberships?.membershipsDetail;
  const [ membershipToSwitchId, setMembershipToSwitchId ] = useState<string>('1');
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isOpenChangeMembershipInfoModal, setIsOpenChangeMembershipInfoModal ] = useState(false);
  const [ isOpenSuspendingModal, setIsOpenSuspendingModal ] = useState(false);
  const { searchParams } = useNavigate();

  const [ userId ] = useLocalStorage(USER_ID, '');
  const { data } = useGetUserActiveMembershipIdQuery({
    variables: {
      userId: Number(userId),
    },
  });
  const [ setNewTypeOfMembership ] = useSetNewTypeOfMembershipMutation({
    refetchQueries: [
      {
        query: GetUserActiveMembershipIdDocument,
        variables: {
          userId: Number(userId),
        },
      },
      {
        query: UserDataQuoteDocument,
        variables: {
          userId: Number(userId),
        },
      },
      {
        query: DeviceList,
      },
      {
        query: UserDocument,
        variables: {
          userId: Number(userId),
        },
      },
    ],
  });
  const { setSnackbar } = snackbarCacheControl;

  const handleActivate = (newMembershipId: number, selectedDeviceIds: number[] | undefined = undefined) => {
    setIsOpen(false);
    setIsOpenChangeMembershipInfoModal(false);
    setIsOpenSuspendingModal(false);
    try {
      setNewTypeOfMembership({ variables: { newMembershipId, devicesNotToSuspend: selectedDeviceIds } })
        .then(() => {
          setSnackbar('success', 'SET_NEW_MEMBERSHIP_SUCCESSFUL');
        })
        .catch((e) => {
          console.error(e);
          setSnackbar('error', 'SET_NEW_MEMBERSHIP_ERROR');
        });
    
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const openModalAccounts = searchParams.get('openModalAccounts');
    if (openModalAccounts) setIsOpen(true);
  }, [ searchParams ]);

  return (
    <div>
      <ChangeMembershipInfoModal
        membershipToSwitchId={membershipToSwitchId}
        isOpen={isOpenChangeMembershipInfoModal}
        setIsOpen={setIsOpenChangeMembershipInfoModal}
        handleActivate={handleActivate}
        openSuspendingModal={() => setIsOpenSuspendingModal(true)}
      />
      <SuspendDevicesModal
        membershipToSwitchId={membershipToSwitchId}
        isOpen={isOpenSuspendingModal}
        setIsOpen={setIsOpenSuspendingModal}
        handleActivate={handleActivate}
      />
      <Dialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        // maxWidth="xs"
        fullScreen
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: '#f6f6f6',
          },
        }}
      >
        <CancelIcon
          onClick={() => setIsOpen(false)}
          sx={{
            position: 'absolute',
            right: '16px',
            top: '16px',
            color: 'lightgrey',
          }}
        />
        <DialogContent>
          <Typography
            variant="h5"
            textAlign="center"
            mb={1}
            mt={1}
          >
            Zvolte účet
          </Typography>
          <Stack spacing={3} mt={2} direction="column-reverse">
            {accountsData?.map((item) => {
              return (
                <AccountVariant
                  key={item?.id}
                  isActive={item?.id === data?.user?.membership.id}
                  membershipName={item?.name as string}
                  annumPrice={item?.price as number}
                  membershipDescItems={[]}
                  isFullInfo={true}
                  onClick={() => {
                    if (item?.id === data?.user?.membership.id) return;

                    if ((item?.price ?? 0) > (data?.user?.creditBalance ?? 0)) {
                      setIsOpen(false);
                      setNoCreditOpen(true);
                      return;
                    }

                    setMembershipToSwitchId(item?.id ?? MEMBERSHIP_STARTER_ID);
                    setIsOpen(false);
                    setIsOpenChangeMembershipInfoModal(true);
                  }}
                  deviceCountMembership={item?.deviceCountMembership as number}
                  dataFree={item?.dataFree as number}
                />
              );
            })}
          </Stack>
        </DialogContent>
      </Dialog>
      <Typography variant="sectionHeadline">Aktivní varianta účtu</Typography>
      <Box>
        {accountsData?.map((item) =>
          item?.id === data?.user?.membership.id && (
            <AccountVariant
              key={item?.id}
              isActive={item?.id === data?.user?.membership.id}
              membershipName={item?.name as string}
              annumPrice={item?.price as number}
              membershipDescItems={[]}
              onClick={() => setIsOpen(true)}
              isFullInfo={false}
              deviceCountMembership={item?.deviceCountMembership as number}
              dataFree={item?.dataFree as number}
            />
          )
        )}
      </Box>
    </div>
  );
};

export default Membership;
