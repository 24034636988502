import React, { FC } from 'react';
import { IAttachmentsEventItemProps } from '../types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  useEventByIdQuery,
  useExpensesByEventQuery,
  useRefuelingByEventIdQuery,
} from '@state/mechis-backend/generated/schema';
import {
  Alert,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mechis/elements';
import {
  EVENT_TYPE_EXPENSE,
  EVENT_TYPE_MAINTENANCE,
  EVENT_TYPE_REFUELING,
  EventType,
} from '@configurations/constants/eventTypes';
import useEvent from '@hooks/useEvent';
import useTech, { UnitType } from '@hooks/useTech';
import NumberFormat from 'react-number-format';
import {
  MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT,
  THOUSAND_SEPARATOR,
} from '@configurations/constants/app';
import { cutToTwoDecimalPlaces } from '@utilities/cutToTwoDecimalPlaces';
import { tableOutputDate } from '@utilities/app';
import { OutputDateFormats } from '@configurations/types/global';

const ExpenseEvent = ({ id }: { id: string }) => {
  const { getTechUnit } = useTech();
  const { data } = useExpensesByEventQuery({
    variables: {
      eventId: Number(id),
      offset: 0,
      limit: MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT,
    },
  });

  return (
    <Typography variant="body2">
      <NumberFormat
        suffix={getTechUnit(UnitType.Currency, true)}
        value={data?.expensesByEvent?.expense[0].event?.priceTotal ?? '0'}
        displayType="text"
        thousandSeparator={THOUSAND_SEPARATOR}
      />
    </Typography>
  );
};

const RefuelingEvent = ({ id }: { id: string }) => {
  const { getTechUnit } = useTech();
  const { data } = useRefuelingByEventIdQuery({
    variables: {
      eventId: Number(id),
    },
  });
  const totalPrice = data?.refuelingByEvent?.reduce((acc, refueling) => {
    return acc + (refueling.event.priceTotal ?? 0);
  }, 0);

  return (
    <Typography variant="body2">
      <NumberFormat
        suffix={getTechUnit(UnitType.Currency, true)}
        value={cutToTwoDecimalPlaces(Number(totalPrice))}
        displayType="text"
        thousandSeparator={THOUSAND_SEPARATOR}
      />
    </Typography>
  );
};

const AttachmentsEventItem: FC<IAttachmentsEventItemProps> = ({
  id,
  onDetail,
}) => {
  const { name, eventIcon, eventColor } = useEvent(+id);
  const { getTechUnit } = useTech();
  const { data, loading, error } = useEventByIdQuery({ variables: { eventId: Number(id) }});
  const eventType = Number(data?.event?.eventType.id) as EventType;

  if (loading) {
    return <Skeleton height={32} />;
  }

  if (error) {
    return (
      <Alert severity="error">Nepodařilo se načíst informace o eventu</Alert>
    );
  }

  return (
    <Paper onClick={onDetail ? () => onDetail() : undefined}>
      <Stack m={1} direction="row" justifyContent="space-between">
        <Stack spacing={1} direction="row" alignItems="center">
          <IconButton
            size="small"
            isSquared
            sx={{
              background: `linear-gradient(135deg, ${
                eventColor(eventType)['200']
              }, ${eventColor(eventType)['100']})`,
            }}
          >
            {eventIcon(eventType, 'small')}
          </IconButton>
          <Stack textAlign={'start'}>
            <Typography variant="h6" color={'primary'}>
              {name ?? '---'}
            </Typography>
            <Typography variant="subtitle1" color={'grey'}>
              <NumberFormat
                value={data?.event?.stateOdometer ?? 0}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
                suffix={getTechUnit(UnitType.Odometer, true)}
              />
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} direction={'row'} alignItems="center">
          <Stack textAlign={'end'}>
            <Typography
              variant="subtitle1"
              component="span"
              color="primary"
              lineHeight={1}
            >
              {tableOutputDate(
                data?.event?.eventDate,
                OutputDateFormats.Classic
              )}
            </Typography>
            {(eventType === EVENT_TYPE_EXPENSE ||
              eventType === EVENT_TYPE_MAINTENANCE) && <ExpenseEvent id={id} />}
            {eventType === EVENT_TYPE_REFUELING && <RefuelingEvent id={id} />}
          </Stack>
          {onDetail && (
            <IconButton color="secondary" size="small">
              <ChevronRightIcon fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AttachmentsEventItem;
