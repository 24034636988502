import React from 'react';
import { Box, Typography, Stack } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';
import Transaction from '@mechis/blocks/Transaction';

const TransactionsWidget: React.FC<IProps> = ({ transactions }) => {
  const { t } = useTranslation();

  const getNameByMembershipName = (name: string) => {
    switch (name) {
      case 'professional':
        return t('cloud.transactions.monthlyMembership.professional');
      case 'advanced':
        return t('cloud.transactions.monthlyMembership.advanced');
      case 'starter':
        return t('cloud.transactions.monthlyMembership.starter');
      default:
        return name;
    }
  };

  return (
    <Box mt={2}>
      <Typography variant="sectionHeadline">
        {t('cloud.transactions.title')}
      </Typography>
      <Stack spacing={2} mt={2}>
        {transactions?.map((transaction, index) => {
          return (
            <Transaction
              key={index}
              date={transaction?.date}
              priceTotal={transaction?.value}
              isEven={index % 2 === 0}
              title={getNameByMembershipName(transaction?.description ?? '')}
              kilometersDifference={0}
              type={5}
              kilometers={0}
              name={transaction?.type?.name}
            />
          );
        })}
        {!transactions && (
          <Box>
            <Typography>{t('cloud.transactions.noTransactions')}</Typography>
          </Box>
        )}
      </Stack>
    </Box>

  );
};

export default TransactionsWidget;
