import React, { FC, useEffect } from 'react';
import { useAttachments } from '@mechis/sections/Attachments';
import {
  AttachmentsContentFilters,
  AttachmentsContentControl,
  Avatar,
  Selected,
  Attachments,
  Event,
  Widget,
} from '@mechis/sections/Attachments/content';
import { Stack, Box } from '@mechis/elements';

interface AttachmentsContentProps {
  variant?: 'attachmentsSelected' | 'attachments' | 'attachmentsEvent' | 'attachmentsWidget' | 'avatar'
  isUpdateAvatar?: boolean
}

const AttachmentsContent: FC<AttachmentsContentProps> = ({ 
  variant,
  isUpdateAvatar = false,
}) => {
  const {
    selectedFiles,
    selectedFilesState,
    allAttachmentsEvent,
    attachmentsEvent,
    loadAttachmentsEvent,
  } = useAttachments();

  useEffect(() => {
    if (variant === 'attachmentsEvent') {
      loadAttachmentsEvent();
    }
  }, []);

  switch (variant) {
    case 'avatar': return <Avatar isUpdateAvatar={isUpdateAvatar} />;
    case 'attachmentsSelected':
      if (selectedFilesState.length === 0) return <AttachmentsContentControl />;
      return (
        <Stack>
          <AttachmentsContentFilters />
          {selectedFiles.length === 0 && (
            <Box textAlign="center" mt={2}>
              <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy tohoto typu</em>
            </Box>
          )}
          <Selected />
        </Stack>
      );
    case 'attachmentsWidget': return <Widget />;
    case 'attachmentsEvent':
      if (selectedFiles.length === 0 && allAttachmentsEvent?.length === 0) {
        return <AttachmentsContentControl />;
      }

      return (
        <Stack>
          <AttachmentsContentFilters />
          {(selectedFiles.length === 0 && attachmentsEvent?.length === 0) && (
            <Box textAlign="center" mt={2}>
              <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy tohoto typu</em>
            </Box>
          )}
          <Selected />
          <Event />
        </Stack>
      );
    case 'attachments':
      return (
        <Stack>
          <AttachmentsContentFilters />
          <Attachments />
        </Stack>
      );
    default:
      return <>Widget nenakonfigurován!</>;
  }
};

export default AttachmentsContent;
