import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mechis/elements';
import useTech from '@hooks/useTech';
import useHeader from '@hooks/useHeader';

const NoSpace: FC = () => {
  useHeader({
    headline: 'appBar.headline.noSpace',
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useTech();
  
  return (
    <>
      <Box textAlign="center">
        <Stack spacing={2}>
          <Typography
            variant="h2"
          >
            {t('noSpace.title')}
          </Typography>
          <Typography
            variant="body1"
          >
            {t('noSpace.description')}
          </Typography>
          <Typography
            variant="h3"
          >
            {t('noSpace.nextSteps')}
          </Typography>
          <Box textAlign="center" my={2}>
            <Typography
              variant="h5"
            >
              {t('noSpace.increaseStorage')}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/app/mechis?openModalDataQuote=true')}
              sx={{ marginY: '15px' }}
            >
              {t('noSpace.increaseStorageButton')}
            </Button>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography
              variant="h5"
            >
              {t('noSpace.changeMembership')}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/app/mechis?openModalAccounts=true')}
              sx={{ marginY: '15px' }}
            >
              {t('noSpace.changeMembershipButton')}
            </Button>
          </Box>
          <Box textAlign="center" my={2}>
            <Typography
              variant="h5"
            >
              {t('noSpace.deleteData')}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(`/app/${techId}/tech-detail/records`)}
              sx={{ marginY: '15px' }}
            >
              {t('noSpace.deleteDataButton')}
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default NoSpace;
