import React, { FC } from 'react';
import { useRefuelingByEventIdQuery } from '@state/mechis-backend/generated/schema';
import { v4 as uuid } from 'uuid';
import { Box } from '@mechis/elements';
import Loader from '@mechis/blocks/Loader';
import ErrorIcon from '@mui/icons-material/Error';
import SimpleTable from '@mechis/blocks/SimpleTable';
import { useTranslation } from 'react-i18next';
import { IRefuelingProps } from '@screens/TechDetail/EventDetail/types';
import useTech, { UnitType } from '@hooks/useTech';
import { cutToTwoDecimalPlaces } from '@utilities/cutToTwoDecimalPlaces';

const Preview: FC<IRefuelingProps> = ({ id, onUpdate }) => {
  const { t } = useTranslation();
  const { getTechUnit } = useTech();
  // TODO: after MVP, change to unitType litry / galony

  const { data: refuelingInfo, loading: refuelingInfoLoading, error: refuelingInfoError } = useRefuelingByEventIdQuery({
    variables: {
      eventId: Number(id),
    },
  });

  if (refuelingInfoLoading) {
    return (
      <Loader />
    );
  }

  if (refuelingInfoError) {
    return (
      <div>
        <ErrorIcon />
      </div>
    );
  }

  return (
    <>
      <Box mb={3}>
        {refuelingInfo?.refuelingByEvent.map((refueling) =>
          <SimpleTable
            key={uuid()}
            title={t(`fuelTypes.${refueling.fuelType?.fuelName}`)}
            ctas={[
              {
                title: t('common.update'),
                color: 'secondary',
                onClick: () => onUpdate('refueling'),
              },
            ]}
            data={[
              { 
                label: t('techDetail.eventDetail.refueling.totalPrice'),
                value: `${cutToTwoDecimalPlaces(Number(refueling.event.priceTotal)) ?? '-'}${getTechUnit(UnitType.Currency, true)}`,
              },
              { 
                label: t('techDetail.eventDetail.refueling.unitNumber'),
                value: `${cutToTwoDecimalPlaces(Number(refueling.unitNumber)) ?? '-'}${getTechUnit(UnitType.Litre, true)}`,
              },
              {
                label: t('techDetail.eventDetail.refueling.pricePerUnit'),
                value: `${cutToTwoDecimalPlaces(Number(refueling.pricePerUnit)) ?? '-'}${getTechUnit(UnitType.Currency, true)}`,
              },
              {
                label: t('techDetail.eventDetail.refueling.fullTank'),
                value: refueling.fullTank ? t('common.yes') : t('common.no'),
              },
              {
                label: t('techDetail.eventDetail.refueling.skipTank'),
                value: refueling.skipTank ? t('common.yes') : t('common.no'),
              },
            ]}
          />
        )
        }
      </Box>
    </>
  );
};

export default Preview;
