import React, { useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  SwipeableDrawer,
  Grid,
} from '@mechis/elements';
import DirectionsCarFilledRoundedIcon from '@mui/icons-material/DirectionsCarFilledRounded';
import AddBoxIcon from '@mui/icons-material/AddBox';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import useIcon from '@mechis/icons/useIcon';
import { IProps } from '@mechis/sections/AppNavigation/AppMobileNavigation/types';
import useNavigate from '@hooks/useNavigate';
import { useTranslation } from 'react-i18next';
import { ICreateBlockType } from '@mechis/blocks/CreateBlock/types';
import {
  NewTech,
  Odometer,
  Refueling,
  Expenses,
  Maintenance,
  Reminders,
} from './styles';
import useAuth from '@hooks/useAuth';
import useDevices from '@hooks/useDevices';
import { isIOS, isMobile } from 'react-device-detect';
import useTech from '@hooks/useTech';
import { colors } from '@mui/material';

const isIPhoneXOrNewer = () => {
  return (
    isIOS &&
    isMobile &&
    (window.screen.height === 812 || window.screen.height === 896)
  );
};

const AppMobileNavigation: React.FC<IProps> = ({
  onHandleCreateLink,
}) => {
  const { navigate } = useNavigate();
  const { userHasNoDevices} = useDevices();
  const { t } = useTranslation();
  const [ isCreateOpened, setIsCreateOpened ] = useState<boolean>(false);
  const { techId } = useTech();
  const { isComgateUser, executeCallbackOrRedirectToNoSpace } = useAuth();

  const handleBottomNavigationChange = (newValue: number) => {
    switch (newValue) {
      case 0:
        navigate('/app');
        return;
      case 1: {
        setIsCreateOpened(true);
        return;
      }
      case 2:
        navigate('/app/offer');
        return;
      default:
        return;
    }
  };

  // todo: this is just dummy function for now, will be refactored using theme colors for different RECORDS later on
  // eslint-disable-next-line
  const resolveType = (title: string): ICreateBlockType => {
    if (title === 'Tachometr') return 'odometer';
    if (title === 'Tankování') return 'refueling';
    if (title === 'Údržba') return 'maintenance';
    if (title === 'Výdaje') return 'expenses';
    return 'default';
  };

  return (
    <>
      <BottomNavigation
        sx={isIPhoneXOrNewer() ? { height: '66px', paddingBottom: '10px' } : undefined}
        showLabels
        onChange={(event, newValue: number) => handleBottomNavigationChange(newValue)}
      >
        <BottomNavigationAction
          label={t('components.bottomNavigation.machines')}
          icon={
            <DirectionsCarFilledRoundedIcon
              color="primary"
              fontSize="large"
            />
          }
        />
        <BottomNavigationAction
          icon={
            <AddBoxIcon
              sx={{ fontSize: '48px' }}
              color="secondary"
              fontSize="large"
            />
          }
        />
        <BottomNavigationAction
          label={t('components.bottomNavigation.offer')}
          icon={
            <MenuBookRoundedIcon
              color="primary"
              fontSize="large"
            />
          }
        />
      </BottomNavigation>
      <SwipeableDrawer
        PaperProps={{
          sx: {
            width: (theme) => `calc(100% - ${theme.spacing(4)})`,
            left: (theme) => theme.spacing(2),
            padding: (theme) => theme.spacing(2),
            borderRadius: (theme) => `${theme.radiuses.small}px`,
            bottom: '50px',
            backgroundColor: (theme) => theme.palette.background.default,
            overflowY: 'inherit',
          },
        }}
        anchor="bottom"
        open={isCreateOpened}
        onClose={() => setIsCreateOpened(false)}
        onOpen={() => setIsCreateOpened(true)}
        sx={{ minHeight: '80vh' }}
      >

        <NewTech
          onClick={() => {
            onHandleCreateLink('/app/new-tech');
            setIsCreateOpened(false);
          }}
        >
          {useIcon('newTech', {
            color: 'primary',
            sx: {
              fontSize: '65px',
            },
          })}
          <span style={{ opacity: 0.7 }}>Přidej techniku</span>
        </NewTech>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Odometer
              className={`bg-odd ${userHasNoDevices && 'bg-disabled'}`}
              onClick={() => {
                executeCallbackOrRedirectToNoSpace(
                  () => onHandleCreateLink(`/app/${techId}/new-odometer`)
                );
                setIsCreateOpened(false);
              }}
            >
              {useIcon('newOdometer', {
                fontSize: 'large',
                color: userHasNoDevices ? 'disabled' : undefined,
                htmlColor: colors['grey'][800],
              })}
              <span>Aktualizuj tachometr</span>
            </Odometer>
          </Grid>
          <Grid item xs={4}>
            <Refueling
              className={`bg-even ${userHasNoDevices && 'bg-disabled'}`}
              onClick={() => {
                executeCallbackOrRedirectToNoSpace(
                  () => onHandleCreateLink(`/app/${techId}/new-refueling`)
                );
                setIsCreateOpened(false);
              }}
            >
              {useIcon('newRefueling', {
                fontSize: 'large',
                color: userHasNoDevices ? 'disabled' : undefined,
                htmlColor: colors['indigo'][800],
              })}
              <span>Nové tankování</span>
            </Refueling>
          </Grid>
          <Grid item xs={4}>
            <Expenses
              className={`bg-odd ${userHasNoDevices && 'bg-disabled'}`}
              onClick={() => {
                executeCallbackOrRedirectToNoSpace(
                  () => onHandleCreateLink(`/app/${techId}/new-expenses`)
                );
                setIsCreateOpened(false);
              }}
            >
              {useIcon('newExpenses', {
                fontSize: 'large',
                color: userHasNoDevices ? 'disabled' : undefined,
                htmlColor: colors['red'][800],
              })}
              <span>Přidej výdaj</span>
            </Expenses>
          </Grid>
          {!isComgateUser() && (
            <Grid item xs={4}>
              <Maintenance
                className={`bg-even ${userHasNoDevices && 'bg-disabled'}`}
                onClick={() => {
                  executeCallbackOrRedirectToNoSpace(
                    () => onHandleCreateLink(`/app/${techId}/new-maintenance`)
                  );
                  setIsCreateOpened(false);
                }}
              >
                {useIcon('newMaintenance', {
                  fontSize: 'large',
                  color: userHasNoDevices ? 'disabled' : undefined,
                  htmlColor: colors['grey'][800],
                })}
                <span>Eviduj údržbu</span>
              </Maintenance>
            </Grid>
          )}
          <Grid item xs={4}>
            <Reminders
              className="bg-disabled"
              onClick={() => {
                onHandleCreateLink(`/app/${techId}/new-reminders`);
                setIsCreateOpened(false);
              }}
            >
              {useIcon('newReminders', {
                fontSize: 'large',
                color: 'disabled',
              })}
              <span>Přidej upomínku</span>
            </Reminders>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
};

export default AppMobileNavigation;
