import React, { FC, useState } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CategoryIcon from '@mui/icons-material/Category';
import Notifications from '@mui/icons-material/Notifications';
import AccordionMenuItem from '@mechis/blocks/AccordionMenuItem';
import useTech from '@hooks/useTech';

const TechSettings: FC = () => {
  useHeader({
    headline: 'appBar.headline.techSettings',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });

  const { techId } = useTech();
  const [ expanded, setExpanded ] = useState<string | false>('panel1');

  const handleChange = (id: string) => {
    if (expanded === id) {
      setExpanded(false);
    } else {
      setExpanded(id);
    }
  };

  return (
    <>
      <AccordionMenuItem
        id="block-1"
        title="Změna fotky techniky"
        icon={<AddAPhotoIcon fontSize="small" />}
        expanded={expanded}
        handleChange={handleChange}
        link={`/app/${techId}/tech-detail/settings/edit-avatar`}
      />
      <AccordionMenuItem
        id="block-2"
        title="Měna"
        icon={<CurrencyExchangeIcon fontSize="small" />}
        iconColor="secondary"
        expanded={expanded}
        handleChange={handleChange}
        link={`/app/${techId}/tech-detail/settings/change-currency`}
      />
      <AccordionMenuItem
        id="block-3"
        title="Jednotky"
        icon={<CategoryIcon fontSize="small" />}
        iconColor="secondary"
        expanded={expanded}
        handleChange={handleChange}
        link={`/app/${techId}/tech-detail/settings/change-units`}
      />
      <AccordionMenuItem
        id="block-4"
        title="Notifikace údržby"
        icon={<Notifications fontSize="small" />}
        iconColor="secondary"
        expanded={expanded}
        handleChange={handleChange}
        link={`/app/${techId}/tech-detail/settings/notification-settings`}
      />
      {/* todo: please dont delete this code, unless @marekkolac says otherwise :) */}
      {/*
      <AccordionMenuItem
        id="block-4"
        title="Ukázka kategorie 1"
        icon={<CategoryIcon fontSize="small" />}
        iconColor="background"
        expanded={expanded}
        handleChange={handleChange}
      >
        <AccordionMenuItem
          id="block-4-1"
          title="Převodovky"
          icon={<CategoryIcon fontSize="small" />}
          iconColor="background"
          expanded={false}
          handleChange={handleChange}
          link="/app"
        />
        <AccordionMenuItem
          id="block-4-2"
          title="Pneumatiky"
          icon={<CategoryIcon fontSize="small" />}
          iconColor="background"
          expanded={false}
          handleChange={handleChange}
          link="/app"
        />
        <AccordionMenuItem
          id="block-4-3"
          title="Karburátory"
          icon={<CategoryIcon fontSize="small" />}
          iconColor="background"
          expanded={false}
          handleChange={handleChange}
          link="/app"
        />
      </AccordionMenuItem>
      <AccordionMenuItem
        id="block-5"
        title="Ukázka kategorie 2"
        icon={<CategoryIcon fontSize="small" />}
        iconColor="warning"
        expanded={expanded}
        handleChange={handleChange}
      >
        <AccordionMenuItem
          id="block-5-1"
          title="Svíčky"
          icon={<CategoryIcon fontSize="small" />}
          iconColor="warning"
          expanded={expanded}
          handleChange={handleChange}
          link="/app"
        />
        <AccordionMenuItem
          id="block-5-1"
          title="Chlebíčky"
          icon={<CategoryIcon fontSize="small" />}
          iconColor="warning"
          expanded={expanded}
          handleChange={handleChange}
          link="/app"
        />
      </AccordionMenuItem>
      */}
      <AccordionMenuItem
        id="block-3"
        title="Smazat techniku"
        icon={<DeleteIcon fontSize="small" />}
        iconColor="error"
        expanded={expanded}
        handleChange={handleChange}
        link={`/app/${techId}/tech-detail/settings/delete-tech`}
      />
    </>
    
  );
};

export default TechSettings;
