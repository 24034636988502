import React from 'react';
import { useAttachments } from '@mechis/sections/Attachments';
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mechis/elements';
import { useNavigate } from 'react-router-dom';
import useTech from '@hooks/useTech';
import AttachmentsEventItem from './AttachmentsEventItem';

const AttachmentsMultipleEventsModal = () => {
  const { isEventDetailIntent, setIsEventDetailIntent } = useAttachments();
  const navigate = useNavigate();
  const { techId } = useTech();

  const handleNavigate = (id: string) => {
    setIsEventDetailIntent(null);
    navigate(`/app/${techId}/tech-detail/record/${id}`);
  };

  return (
    <Dialog
      open={isEventDetailIntent}
      PaperProps={{ sx: { backgroundColor: '#f6f6f6' } }}
      onClose={() => setIsEventDetailIntent(null)}
    >
      <DialogContent sx={{ textAlign: 'center', padding: 2 }}>
        <Typography variant="subtitle2">Výskyt v záznamech</Typography>
        <Stack sx={{ width: '100%' }} spacing={2} mt={3}>
          {isEventDetailIntent?.map((item: string) => (
            <AttachmentsEventItem
              key={item}
              id={item}
              onDetail={() => handleNavigate(item)}
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AttachmentsMultipleEventsModal;
