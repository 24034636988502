import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FrontendLayout from '@mechis/layouts/FrontEndLayout';
import AppLayout from '@mechis/layouts/AppLayout';
import Login from '@screens/Login';
import Registration from '@screens/Registration';
import ForgottenPassword from '@screens/ForgottenPassword';
import PasswordReset from '@screens/PasswordReset';
import TechOverview from '@screens/TechOverview';
import TechDetail from '@screens/TechDetail';
import Info from '@screens/TechDetail/Info';
import Records from '@screens/TechDetail/Records';
import TechSettings from '@screens/TechDetail/TechSettings';
import EditAvatar from '@screens/TechDetail/TechSettings/EditAvatar';
import ChangeCurrency from '@screens/TechDetail/TechSettings/ChangeCurrency';
import DeleteTech from '@screens/TechDetail/TechSettings/DeleteTech';
import EventDetail from '@screens/TechDetail/EventDetail';
import UpdateEvent from '@screens/TechDetail/EventDetail/UpdateEvent';
import Attachments from '@screens/TechDetail/Attachments';
import Analysis from '@screens/TechDetail/Analysis';
import MaintenancePlan from '@screens/TechDetail/MaintenancePlan';
import TechSelection from '@screens/NewTech/TechSelection';
import NewTech from '@screens/NewTech';
import NewOdometer from '@screens/NewOdometer';
import NewRefueling from '@screens/NewRefueling';
import NewExpenses from '@screens/NewExpenses';
import Payment from '@screens/Payment';

// new maintenance
import NewMaintenance from '@screens/NewMaintenance';
import NewMaintenanceStep1 from '@screens/NewMaintenance/steps/Step1';
import NewMaintenanceStep2 from '@screens/NewMaintenance/steps/Step2';
import NewMaintenanceStep3 from '@screens/NewMaintenance/steps/Step3';

import NewReminders from '@screens/NewReminders';
import Offer from '@screens/Offer';
import RegistrationEmailSended from '@screens/RegistrationEmailSended';
import EmailVerified from '@screens/EmailVerified';
import MyProfile from '@screens/MyProfile';
import Settings from '@screens/Settings';
import Mechis from '@screens/Mechis';
import { NotSupportedPage } from '@screens/NotSupportedPage/NotSupportedPage';
import ChangeUnits from '@screens/TechDetail/TechSettings/ChangeUnits';
import NoSpace from '@screens/NoSpace';
import NotificationsSettings from '@screens/TechDetail/TechSettings/NotificationsSettings';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<FrontendLayout />}>
        <Route index element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route
          path="registration-email-sended/:email"
          element={<RegistrationEmailSended />}
        />
        <Route path="email-verification/:token" element={<EmailVerified />} />
        <Route path="forgotten-password" element={<ForgottenPassword />}>
          <Route path=":email" element={<ForgottenPassword />} />
        </Route>
        <Route path="password-reset" element={<PasswordReset />} />
      </Route>
      <Route path="not-supported" element={<NotSupportedPage />} />
      <Route path="payment/:status" element={<Payment />} />
      <Route path="app" element={<AppLayout />}>
        <Route index element={<TechOverview />} />
        <Route path="offer" element={<Offer />} />
        <Route path="my-profile" element={<MyProfile />} />
        <Route path="settings" element={<Settings />} />
        <Route path="mechis" element={<Mechis />} />
        <Route path="no-space" element={<NoSpace />} />
        <Route path="new-tech">
          <Route index element={<TechSelection />} />
          <Route path=":addFlow/:stepId" element={<NewTech />} />
        </Route>
        <Route path=":techId">
          <Route path="tech-detail">
            <Route index element={<TechDetail />} />
            <Route path="info" element={<Info />} />
            <Route path="attachments" element={<Attachments />} />
            <Route path="records" element={<Records />} />
            <Route path="record/:id">
              <Route index element={<EventDetail />} />
              <Route path="update/:type" element={<UpdateEvent />} />
            </Route>
            <Route path="maintenance-plan" element={<MaintenancePlan />} />
            <Route path="analysis" element={<Analysis />} />
            <Route path="settings">
              <Route index element={<TechSettings />} />
              <Route path="edit-avatar" element={<EditAvatar />} />
              <Route path="change-currency" element={<ChangeCurrency />} />
              <Route path="change-units" element={<ChangeUnits />} />
              <Route path="notification-settings" element={<NotificationsSettings />} />
              <Route path="delete-tech" element={<DeleteTech />} />
            </Route>
          </Route>
          <Route path="new-odometer" element={<NewOdometer />} />
          <Route path="new-refueling">
            <Route index element={<NewRefueling />} />
            <Route path=":step" element={<NewRefueling />} />
          </Route>
          <Route path="new-expenses">
            <Route index element={<NewExpenses />} />
            <Route path=":step" element={<NewExpenses />} />
          </Route>
          <Route path="new-maintenance">
            <Route index element={<NewMaintenance />} />
            <Route path=":intervalId" element={<NewMaintenance />} />
            <Route path="1" element={<NewMaintenanceStep1 />} />
            <Route path="2" element={<NewMaintenanceStep2 />} />
            <Route path="3" element={<NewMaintenanceStep3 />} />
          </Route>
          <Route path="new-reminders" element={<NewReminders />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
