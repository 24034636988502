import { Box } from '@mechis/elements';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/material/styles';

const CIRCLE_SIZE = '10rem';
const FONT_SIZE = `calc(${CIRCLE_SIZE} / 2)`;

export const pulse = keyframes`
  0% { transform: scale(1) }
  50% {  transform: scale(1.08) }
  100% { transform: scale(1) }
`;

export const Circle = styled(Box)(({ theme }) => ({
  width: CIRCLE_SIZE,
  height: CIRCLE_SIZE,
  borderRadius: '50%',
  marginTop: `calc(-${CIRCLE_SIZE} / 2)`,
  position: 'absolute',
  left: `calc(50% - ${CIRCLE_SIZE} / 2)`,
  boxShadow: theme.shadows[3],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: FONT_SIZE,
  color: theme.palette.common.white,
  '& svg': {
    animation: `${pulse} 2s infinite`,
  },
}));

export const Top = styled('aside')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '30vh',
  borderBottomRightRadius: `${theme.radiuses.large}px`,
  borderBottomLeftRadius: `${theme.radiuses.large}px`,
  ...theme.mixins.bgGradientSolidVaultMobile,
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-10),
  padding: theme.spacing(2.5),
}));

export const TextWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: `calc(${CIRCLE_SIZE} / 2 + ${theme.spacing(2)})`,
}));

export const LoaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));
