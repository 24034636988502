import React, { FC, useEffect, useState } from 'react';
import {
  useAttachments,
  IAttachmentsGridItemProps,
  GridItemHeadline,
} from '@mechis/sections/Attachments';
import { Grid, Box, Skeleton } from '@mechis/elements';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import Logo from '@mechis/blocks/Logo/Logo2';

// todo: finish or delete commeted props here
const AttachmentsGridItem: FC<IAttachmentsGridItemProps> = ({
  key,
  variant,
  src,
  filePreview,
  alt,
  type,
  name,
  // size,
  // onDelete,
  onClick,
}) => {
  const { getType, getFileURL } = useAttachments();
  const [ source, setSource ] = useState<string | null>('');
  const [ imageLoaded, setImageLoaded ] = useState<boolean>(false);

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const url = await getFileURL(src);
        setSource(url);
      } catch (error) {
        console.log('Error fetching avatar URL:', error);
        setSource(null);
      }
    };

    if (filePreview) {
      setSource(filePreview);
    } else {
      fetchAvatarUrl();
    }
  }, [ src, filePreview ]);

  const onClickGrid = () => {
    if (getType(type) === 'doc' && source) {
      window.open(source, '_blank');
    }
    if (getType(type) === 'image' && onClick) {
      onClick();
    }
  };

  const getGrid = () => {
    if (type.startsWith('image/')) {
      return (
        <Box sx={{ position: 'relative', width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              opacity: imageLoaded ? 0 : 1,
              transition: 'opacity 0.5s ease-in-out',
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                '& > div': {
                  position: 'absolute',
                  width: '50px !important',
                  height: '50px !important',
                  left: '34px',
                  top: '25px',
                  opacity: 0.25,
                },
              }}
            >
              <Logo variant="medium" />
              <Skeleton
                variant="rounded"
                width={100}
                sx={{ minWidth: '100%' }}
                height={100}
              />
            </Box>
          </Box>
  
          <img
            src={source || ''}
            alt={alt}
            onLoad={() => {
              setImageLoaded(true);
            }}
            style={{
              width: '100%',
              minHeight: '100px',
              borderRadius: '8px',
              marginBottom: 0,
              visibility: imageLoaded ? 'visible' : 'hidden',
            }}
          />
        </Box>
      );
    }

    switch (type) {
      case 'application/pdf':
        return (
          <>
            <PictureAsPdfIcon color="error" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/msword':
        return (
          <>
            <TextSnippetIcon color="info" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 
        return (
          <>
            <TextSnippetIcon color="info" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'text/plain':
        return (
          <>
            <TextSnippetIcon color="disabled" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/vnd.ms-excel':
        return (
          <>
            <BorderAllIcon color="success" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (
          <>
            <BorderAllIcon color="success" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      case 'text/csv':
        return (
          <>
            <BorderAllIcon color="success" sx={{ fontSize: '4rem' }} />
            <GridItemHeadline>{name}</GridItemHeadline>
          </>
        );
      default: return null;
    }
  };

  return (
    <Grid
      item
      xs={4}
      key={key}
      style={{
        minHeight: '110px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        
      }}
      onClick={onClickGrid}
    >
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          ...(variant === 'selected' && {
            border: '1px dashed lightgrey',
            padding: '8px',
            borderRadius: '8px',
          }),
        }}
      >
        {getGrid()}
      </div>
    </Grid>
  );
};

export const AttachmentsGridLoaderItem = () => {
  return (
    <Grid
      item
      xs={4}
    >
      <Skeleton
        variant="rounded"
        width={118}
        height={118}
      />
    </Grid>
  );
};

export default AttachmentsGridItem;
