import React, { FC, useEffect } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import { Grid, Box, TextField, Button, Stack, Paper, FormControlLabel, Checkbox, Typography } from '@mechis/elements';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  GetNotificationSettingsByDeviceIdDocument,
  useGetNotificationSettingsByDeviceIdQuery,
  useUpdateNotificationSettingForDeviceMutation,
  useUserQuery,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useNavigate } from 'react-router-dom';
import useTech from '@hooks/useTech';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalStorage } from 'react-use';
import { USER_ID } from '@configurations/constants/app';

export interface INotificationsSettingsInputs {
  notificationDistanceRange: number
  notificationDateRange: number
  maintenanceNotificationEnabled: boolean
}

const NotificationsSettings: FC = () => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const navigate = useNavigate();

  const { setSnackbar } = snackbarCacheControl;
  const [ userId ] = useLocalStorage(USER_ID, '');

  const { data } = useGetNotificationSettingsByDeviceIdQuery({
    variables: {
      deviceId: techId,
    },
  });

  const { data: userData } = useUserQuery({
    variables: {
      userId: Number(userId),
    },
  });

  const notificationsSettingsFormSchema = yup.object().shape({
    notificationDistanceRange: yup
      .number()
      .typeError(t('notificationSettings.validation.number'))
      .positive(t('notificationSettings.validation.positive'))
      .required(t('notificationSettings.validation.required')),
    notificationDateRange: yup
      .number()
      .typeError(t('notificationSettings.validation.number'))
      .positive(t('notificationSettings.validation.positive'))
      .required(t('notificationSettings.validation.required')),
    maintenanceNotificationEnabled: yup
      .boolean()
      .required(t('notificationSettings.validation.required')),
  });

  const { control, handleSubmit, register, watch, reset } = useForm<INotificationsSettingsInputs>({
    defaultValues: {
      notificationDistanceRange: Number(data?.device?.notificationDistanceRange) || 1,
      notificationDateRange: Number(data?.device?.notificationDateRange) || 1,
      maintenanceNotificationEnabled: data?.device?.maintenanceNotificationEnabled || false,
    },
    resolver: yupResolver(notificationsSettingsFormSchema),
  });

  useEffect(() => {
    if (data?.device) {
      reset({
        notificationDistanceRange: Number(data.device.notificationDistanceRange) || 1,
        notificationDateRange: Number(data.device.notificationDateRange) || 1,
        maintenanceNotificationEnabled: data.device.maintenanceNotificationEnabled || false,
      });
    }
  }, [ data, reset ]);

  const maintenanceNotificationEnabledWatch = watch('maintenanceNotificationEnabled');

  const [ updateNotificationSettings ] = useUpdateNotificationSettingForDeviceMutation({
    refetchQueries: [
      {
        query: GetNotificationSettingsByDeviceIdDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  const update = async ({
    notificationDateRange,
    notificationDistanceRange,
    maintenanceNotificationEnabled,
  }: INotificationsSettingsInputs) => {
    try {
      await updateNotificationSettings({
        variables: {
          device: {
            id: techId,
            notificationDistanceRange,
            notificationDateRange,
            maintenanceNotificationEnabled,
          },
        },
      });
      setSnackbar('success', 'UPDATE_NOTIFICATION_UNITS_SUCCESSFUL');
      navigate(`/app/${techId}/tech-detail/settings`);
    } catch (error) {
      setSnackbar('error', 'UPDATE_NOTIFICATION_UNITS_ERROR');
    }
  };

  useHeader({
    headline: 'appBar.headline.notificationsSettings',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });

  return (
    <Box mt={4}>
      <Paper>
        <Grid container spacing={4} p={3} pt={0} pb={4}>
          <Grid item xs={12}>
            <Controller
              name="maintenanceNotificationEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  label={
                    <Typography variant="h4">
                      {t('notificationSettings.maintenanceNotificationEnabled')}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      color="primary"
                      size="large"
                      {...register('maintenanceNotificationEnabled')}
                      checked={field.value}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                />
              )}
            />

            <Typography variant="body2" textAlign="center">
              <Trans
                i18nKey="notificationSettings.maintenanceNotificationEnabledDescription"
                values={{ email: userData?.user?.login }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} my={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  id="notificationDateRange"
                  {...field}
                  label={t('notificationSettings.notificationDateRange')}
                  type="number"
                  error={!!formState.errors.notificationDateRange?.message}
                  helperText={formState.errors.notificationDateRange?.message}
                  //disabled if maintenanceNotificationEnabled is false
                  disabled={!maintenanceNotificationEnabledWatch}
                />
              )}
              name="notificationDateRange"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  id="notificationDistanceRange"
                  {...field}
                  label={t(`notificationSettings.notificationDistanceRangeByOdometerUnit.${data?.device?.odometerUnit?.unitName}`)}
                  type="number"
                  error={!!formState.errors.notificationDistanceRange?.message}
                  helperText={formState.errors.notificationDistanceRange?.message}
                  disabled={!maintenanceNotificationEnabledWatch}
                />
              )}
              name="notificationDistanceRange"
              control={control}
            />
          </Grid>
        </Grid>
      </Paper>
      <Stack alignItems="center" justifyContent="center" spacing={1.5} mt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit(update)}
        >
          {t('notificationSettings.save')}
        </Button>
      </Stack>
    </Box>
  );
};

export default NotificationsSettings;
