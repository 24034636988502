import React, { FC, useEffect } from 'react';
import {
  Stack,
  IconButton,
  Typography,
  Box,
} from '@mechis/elements';
import { IBaseProps } from './types';
import useEvent from '@hooks/useEvent';
import {
  AttachmentsControl,
  AttachmentsModal,
  AttachmentsContent,
} from '@mechis/sections/Attachments';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Control, ControlLabel } from '@mechis/sections/Selections/styles';
import { IOwnerState } from '@mechis/sections/Selections/types';
import MutableOdometer from '@mechis/sections/Selections/MutableOdometer/MutableOdometer';
import { useEventByIdQuery } from '@state/mechis-backend/generated/schema';
import MutableDate from '@mechis/sections/Selections/MutableDate/MutableDate';
import { useAttachments } from '@mechis/sections/Attachments';
import { IconButtonColor } from '@mechis/elements/IconButton/types';
import { ControlState } from '@mechis/sections/Selections/types';

const Base: FC<IBaseProps> = ({ id, eventType, children }) => {
  const { name, eventIcon, eventColor } = useEvent(+id);
  const {
    loadAttachmentsEvent,
    allAttachmentsEvent,
    selectedFilesState,
  } = useAttachments();
  const UPLOADED = allAttachmentsEvent?.length || 0;
  const SELECTED = selectedFilesState?.length || 0;
  const ATTACHMENTS_COUNT = UPLOADED+SELECTED;

  const getIconState = () => {
    if (ATTACHMENTS_COUNT === 0) {
      return {
        state: 'default' as ControlState,
        color: 'background' as IconButtonColor,
      };
    }

    if (SELECTED === 0) {
      return {
        state: 'filled' as ControlState,
        color: 'primary' as IconButtonColor,
      };
    } else {
      return {
        state: 'warning' as ControlState,
        color: 'warning' as IconButtonColor,
      };
    }
  };

  const { state, color } = getIconState();

  const { data: eventDateAndOdometer } = useEventByIdQuery({
    variables: {
      eventId: Number(id),
    },
  });

  const ownerState: IOwnerState = {
    state,
  };

  useEffect(() => {
    loadAttachmentsEvent();
  }, [ id ]);

  return (
    <Box mt={-1}>
      <Stack direction="row" justifyContent="space-between">
        <IconButton
          size="large"
          isSquared
          sx={{
            background: `linear-gradient(135deg, ${eventColor(eventType)['200']}, ${eventColor(eventType)['100']})`,
          }}
        >
          {eventIcon(eventType, 'large')}
        </IconButton>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <MutableDate eventId={id} eventDateAndOdometer={eventDateAndOdometer} />
          <MutableOdometer eventId={id} eventDateAndOdometer={eventDateAndOdometer} />
          <AttachmentsControl
            target="modal"
            variant="attachments"
          >
            <Control>
              <IconButton
                size="small"
                color={color}
                isSquared
                sx={{ pointerEvents: 'none' }}
              >
                <AttachFileIcon />
              </IconButton>
              <ControlLabel ownerState={ownerState}>
                <small>{ATTACHMENTS_COUNT}</small>
              </ControlLabel>
            </Control>
          </AttachmentsControl>
          <AttachmentsModal variant="attachments" dialogActions="eventDetail">
            <AttachmentsContent variant="attachmentsEvent" />
          </AttachmentsModal>
        </Stack>
      </Stack>
      <Stack mt={2} sx={{ borderBottom: '2px dashed lightgrey '}} pb={2}>
        <Typography variant="h4" sx={{ lineHeight: 1.25 }}>
          {name}
        </Typography>
        {children}
      </Stack>
    </Box>
  );
};

export default Base;
