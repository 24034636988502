import { useState, useEffect } from 'react';
import { isIOS, isMobileOnly } from 'react-device-detect';

const useDetect = () => {
  const [ isIOSMobilePWA, setIsIOSMobilePWA ] = useState(false);
  const [ isAndroidMobilePWA, setIsAndroidMobilePWA ] = useState(false);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      if (isIOS && isMobileOnly) {
        setIsIOSMobilePWA(true);
      } else {
        setIsAndroidMobilePWA(true);
      }
    }
  }, []);

  return {
    isIOSMobilePWA,
    isAndroidMobilePWA,
  };
};

export default useDetect;
