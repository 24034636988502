import { ChangeEvent, useState, useRef, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import attachmentsState from '@state/models/attachments';
import { useParams } from 'react-router-dom';
import { AttachmentAllowedType } from './types';
import {
  Attachment,
  AttachmentWithEvents,
  useCreateAttachmentMutation,
  useCreateDeviceAttachmentMutation,
  useUpdateDeviceAvatarMutation,
  DevicesDocument,
  DeviceThumbnailImageDocument,
  useDeleteDeviceAttachmentMutation,
  useDeleteAttachmentMutation,
  useDeviceThumbnailImageQuery,
  useAttachmentsByDeviceAndFiltersAndTagsLazyQuery,
  useEventAttachmentsByEventIdLazyQuery,
  useDestroySignatureLazyQuery,
  useDeleteEventAttachmentMutation,
  useCreateEventAttachmentsMutation,
  EventAttachmentsByEventIdDocument,
  UserDocument,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import { FileWithPreview } from '@state/models/attachments/types';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { 
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  getMetadata,
  deleteObject,
} from 'firebase/storage';
import { v4 } from 'uuid';
import {
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  USER_ID,
} from '@configurations/constants/app';
import AvatarEditor from 'react-avatar-editor';
import addDeviceCacheControl from '@state/mutations/addDevice';
import useTech from '@hooks/useTech';
import { ATTACHMENT_LIMIT_LIST } from '@configurations/constants/app';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import { filterSelectionToQuery } from '@hooks/utils';
import { useLocalStorage } from 'react-use';

export const ATTACHEMENTS_FILE_LIMIT = 10; // in MB
export const ATTACHEMENTS_ALLOWED_TYPES: AttachmentAllowedType[] = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/heic',
  'image/heif',
  'image/heic-sequence',
];

const useAttachments = () => {
  const state = useReactiveVar(attachmentsState);
  const [ userId ] = useLocalStorage(USER_ID, '');
  const [ createAttachment ] = useCreateAttachmentMutation({
    refetchQueries: [
      {
        query: UserDocument,
        variables: {
          userId: Number(userId),
        },
      },
    ],
  });
  const { setSnackbar } = snackbarCacheControl;
  const [ isLast, setIsLast ] = useState<any>(false);
  const [ offset, setOffset ] = useState<number>(0);
  const [ isUploading, setIsUploading ] = useState<boolean>(false);
  const [ isAvatarSuccess, setIsAvatarSuccess ] = useState<boolean>(false);
  const [ createDeviceAttachment ] = useCreateDeviceAttachmentMutation();
  const { techId } = useTech();
  const { setThumbnailImage } = addDeviceCacheControl;
  const [ updateDeviceAvatar ] = useUpdateDeviceAvatarMutation({
    onCompleted: () => setSnackbar('success', 'AVATAR_UPDATE_SUCCESSFUL'),
    onError: () => setSnackbar('error','AVATAR_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: DevicesDocument,
      },
      {
        query: DeviceThumbnailImageDocument,
        variables: {
          deviceId: Number(techId),
        },
      },
    ],
  });
  const [ runDeleteDeviceAttachment ] = useDeleteDeviceAttachmentMutation({
    refetchQueries: [
      {
        query: UserDocument,
        variables: {
          userId: Number(userId),
        },
      },
    ],
  });
  const [ runDeleteAttachment ] = useDeleteAttachmentMutation({
    onCompleted: (data) => {
      if (data) {
        setIsDeleteIntent(null);
      }
    },
  });
  const [ eventId, setEventId ] = useState<string>('');
  const params = useParams();
  const { data: deviceImage } = useDeviceThumbnailImageQuery({
    variables: {
      deviceId: Number(techId),
    },
  });
  const CURRENT_AVATAR_ID = deviceImage?.device?.thumbnailImage?.id;
  const { data: selections } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const [
    destroySignature, {
      // eslint-disable-next-line
      data: signatureData,
      error: signatureError,
      // eslint-disable-next-line
      loading: signatureLoading,
    },
  ] = useDestroySignatureLazyQuery();
  const [ deleteEventAttachment ] = useDeleteEventAttachmentMutation({
    refetchQueries: [
      {
        query: EventAttachmentsByEventIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      },
    ],
  });
  // eslint-disable-next-line
  const [ createEventAttachment ] = useCreateEventAttachmentsMutation({
    refetchQueries: [
      {
        query: EventAttachmentsByEventIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      },
    ],
  });
  
  const [ 
    loadAttachments, {
      data: loadedAttachments,
      loading: loadingAttachments,
    },
  ] = useAttachmentsByDeviceAndFiltersAndTagsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      deviceId: techId,
      offset,
      limit: ATTACHMENT_LIMIT_LIST,
      tags: selections?.selections?.tags.map((tag) => Number(tag.id)) || [],
      filters: filterSelectionToQuery(selections?.selections),
    },
    onCompleted: (newData) => {
      setIsLast(newData.attachmentsByDeviceAndFiltersAndTags?.isLast);
      const newAttachments = newData.attachmentsByDeviceAndFiltersAndTags?.attachment || [];
      const oldAttachments = state.attachments || [];
      const finalAttachments = [
        ...oldAttachments,
        ...newAttachments,
      ];
      setAttachments(finalAttachments as Partial<AttachmentWithEvents>[]);
    },
  });

  const [ loadAttachmentsEvent, { data: attachmentsEvent } ] = useEventAttachmentsByEventIdLazyQuery({
    variables: {
      eventId: Number(eventId),
    },
  });

  const setIsModalOpen = (isModalOpened: boolean) => {
    attachmentsState({
      ...state,
      isModalOpened,
    });
  };

  const setView = (view: 'list' | 'grid') => {
    attachmentsState({
      ...state,
      view,
    });
  };

  const setSelectedFiles = (selectedFiles: FileWithPreview[]) => {
    attachmentsState({
      ...state,
      selectedFiles,
    });
  };

  const setUploadedFiles = (uploadedFiles: Partial<Attachment>[]) => {
    attachmentsState({
      ...state,
      uploadedFiles,
    });
  };

  const setAttachments = (attachments: Partial<AttachmentWithEvents>[]) => {
    attachmentsState({
      ...state,
      attachments,
    });
  };
  
  const setFilter = (filter: 'all' | 'image' | 'doc') => {
    attachmentsState({
      ...state,
      filter,
    });
  };

  const setIsDeleteIntent = (isDeleteIntent: Partial<AttachmentWithEvents> | Partial<Attachment> | null) => {
    attachmentsState({
      ...state,
      isDeleteIntent,
    });
  };

  const setIsEventDetailIntent = (isEventDetailIntent: any) => {
    attachmentsState({
      ...state,
      isEventDetailIntent,
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, variant: 'attachments' | 'avatar') => {
    const newFiles = Array.from(event.target.files || []);
    
    const filteredNewFiles = newFiles.filter((file) => 
      file.size <= ATTACHEMENTS_FILE_LIMIT * 1024 * 1024 &&
      ATTACHEMENTS_ALLOWED_TYPES.includes(file.type as AttachmentAllowedType)
    ) as FileWithPreview[];
  
    const newFilesWithPreview = filteredNewFiles.map((file) => {
      if (file.type.startsWith('image/')) {
        file.preview = URL.createObjectURL(file);
      }
      return file;
    });
  
    if (variant === 'attachments') {
      const currentSelectedFiles = attachmentsState().selectedFiles;
      const allFiles = [ ...currentSelectedFiles ];
  
      newFilesWithPreview.forEach((newFile) => {
        const isFileAlreadySelected = allFiles.some(
          (existingFile) => existingFile.name === newFile.name && existingFile.size === newFile.size
        );
        if (!isFileAlreadySelected) {
          allFiles.push(newFile);
        }
      });
  
      setSelectedFiles(allFiles);
    } else if (variant === 'avatar') {
      setSelectedFiles(newFilesWithPreview);
    }
  };
  
  const handleRemoveFile = (fileToRemove: FileWithPreview) => {
    const newAttachments = { ...state };
    newAttachments.selectedFiles = newAttachments.selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(newAttachments.selectedFiles);
    if (fileToRemove.preview) {
      URL.revokeObjectURL(fileToRemove.preview);
    }
  };

  const selectedFilesTotalSize = () => {
    const totalSize = state.selectedFiles.reduce((sum, file) => {
      return sum + file.size;
    }, 0);
  
    return +(totalSize / (1024 * 1024)).toFixed(2);
  };

  const getType = (type: AttachmentAllowedType): 'image' | 'doc' => {
    if (type.startsWith('image/')) return 'image';
    return 'doc';
  };

  const getFilteredSelectedFiles = () => {
    switch (state.filter) {
      case 'image':
        return state.selectedFiles.filter((file) =>
          file.type.startsWith('image/')
        );
      case 'doc':
        return state.selectedFiles.filter((file) =>
          !file.type.startsWith('image/')
        );
      case 'all':
      default:
        return state.selectedFiles;
    }
  };

  const getFilteredAttachments = () => {
    switch (state.filter) {
      case 'image':
        return state.attachments.filter((file: Partial<AttachmentWithEvents>) => 
          file.mimeType && typeof file.mimeType === 'string' && file.mimeType.startsWith('image/')
        );
      case 'doc':
        return state.attachments.filter((file: Partial<AttachmentWithEvents>) => 
          file.mimeType && typeof file.mimeType === 'string' && !file.mimeType.startsWith('image/')
        );
      case 'all':
      default:
        return state.attachments;
    }
  };

  const getFilteredAttachmentsEvent = () => {
    switch (state.filter) {
      case 'image':
        return attachmentsEvent?.eventAttachmentsByEventId.filter((file) => 
          file.attachment.mimeType.startsWith('image/')
        );
      case 'doc':
        return attachmentsEvent?.eventAttachmentsByEventId.filter((file) => 
          !file.attachment.mimeType.startsWith('image/')
        );
      case 'all':
      default:
        return attachmentsEvent?.eventAttachmentsByEventId;
    }
  };

  // todo: type should be from mime enum type
  const isSelectedUploaded = (name: string, size: number, type: string) => {
    return state.uploadedFiles.some((file) =>
      file.originalName === name && file.size === size && file.mimeType === type
    );
  };

  // FIREBASE DECLARATIONS
  const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '',
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const uploadFile = async (file: File, folder: string) => {
    const imageRef = ref(storage, `${folder}/${file.name}`);

    try {
      const snapshot = await uploadBytes(imageRef, file);
      const metadata = await getMetadata(snapshot.ref);

      const resultAttachment = await createAttachment({
        variables: {
          attachment: {
            originalName: file.name,
            storageName: metadata.fullPath,
            version: 1,
            mimeType: metadata.contentType ?? 'text/plain',
            size: metadata.size,
          },
        },
      });

      if (resultAttachment.data?.createAttachment?.id) {
        const newAttachment: Partial<Attachment> = {
          id: Number(resultAttachment.data.createAttachment.id),
          originalName: file.name,
          storageName: metadata.fullPath,
          mimeType: metadata.contentType ?? 'text/plain',
          size: metadata.size,
        };

        return newAttachment;
      }
      
    } catch (error) {
      setSnackbar('error', 'FIREBASE_UPLOAD_ERROR');
    }
  };

  const uploadFiles = async () => {
    if (state.selectedFiles.length === 0) {
      console.log('No files selected for upload.');
      return false;
    }

    setIsUploading(true);
  
    const newUploadedFiles = [ ...state.uploadedFiles ];
    for (const file of state.selectedFiles) {
      if (isSelectedUploaded(file.name, file.size, file.type)) {
        console.log(`File already uploaded: ${file.name}`);
        continue;
      }

      const attachmentFileName = `${file.name} ${v4()}`;
      const attachmentFile = new File([ file ], attachmentFileName, {
        type: file.type, 
        lastModified: file.lastModified,
      });
  
      try {
        const uploadedFile = await uploadFile(attachmentFile, 'attachments');
        if (uploadedFile) {
          newUploadedFiles.push(uploadedFile);
        }
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
        // todo: reenable setSnackbar here
        // setSnackbar('error', 'UPLOAD_FAILED', file.name);
      } finally {
        setIsUploading(false);
      }
    }

    setUploadedFiles(newUploadedFiles);
    return newUploadedFiles;
  };

  const avatarEditor = useRef<AvatarEditor>(null);

  const uploadAvatar = async () => {
    setIsUploading(true);
    const canvas = avatarEditor?.current?.getImageScaledToCanvas();

    canvas?.toBlob(async (blob) => {
      if (blob) {
        const fileName = `avatar-${v4()}.png`;
        const file = new File([ blob ], fileName, {
          type: 'image/png',
        });
        const result = await uploadFile(file, 'avatars');
        if (result) {
          setThumbnailImage({
            id: Number(result.id),
            storageName: result.storageName,
          });
          setIsUploading(false);
          setIsAvatarSuccess(true);
        }
      } else {
        console.error('Failed to convert canvas to Blob.');
        setIsUploading(false);
        setIsAvatarSuccess(false);
      }
    });
  
    return null;
  };
  
  const updateAvatar = async () => {
    setIsUploading(true);
    const canvas = avatarEditor?.current?.getImageScaledToCanvas();

    canvas?.toBlob(async (blob) => {
      if (blob) {
        const fileName = `avatar-${v4()}.png`;
        const file = new File([ blob ], fileName, {
          type: 'image/png',
        });
        const result = await uploadFile(file, 'avatars');
        if (result) {
          const subResult = await createDeviceAttachment({
            variables: {
              attachmentId: Number(result.id),
              deviceId: Number(techId),
            },
          });
          if (subResult) {
            const avatarID = subResult.data?.createDeviceAttachment?.attachment.id;
            await updateDeviceAvatar({
              variables: {
                device: {
                  id: Number(techId),
                  thumbnailImage: {
                    id: Number(avatarID),
                  },
                },
              },
            });
            if (CURRENT_AVATAR_ID) {
              await runDeleteDeviceAttachment({
                variables: {
                  attachmentId: Number(CURRENT_AVATAR_ID),
                  deviceId: Number(techId),
                },
              });
              await runDeleteAttachment({
                variables: {
                  deleteAttachmentId: Number(CURRENT_AVATAR_ID),
                },
              });
            }
            setIsUploading(false);
            setIsAvatarSuccess(true);
          }
        }
      } else {
        console.error('Failed to convert canvas to Blob.');
        setIsUploading(false);
        setIsAvatarSuccess(false);
      }
    });
  
    return null;
  };

  const getFileURL = async (storagePath: string) => {
    const storageRef = ref(storage, storagePath);
    try {
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error('Chyba při získávání URL:', error);
      setSnackbar('error', 'Chyba při získávání URL');
      return null;
    }
  };

  const onDeleteEventAttachment = async(attachment: Partial<AttachmentWithEvents>, idEvent?: string) => {
    try {
      await destroySignature({ variables: { publicId: attachment.storageName || '' }});
      await deleteObject(ref(storage, attachment.storageName));
      await deleteEventAttachment({
        variables: {
          attachmentId: Number(attachment.id),
          eventId: idEvent? Number(idEvent) : Number(eventId),
        },
      });
      await runDeleteAttachment({
        variables: {
          deleteAttachmentId: Number(attachment.id),
        },
      });
      return 'success';
    } catch (e) {
      console.error(e, signatureError);
    }
    return null;
  };

  const onDeleteAttachment = async(attachment: Partial<AttachmentWithEvents>) => {
    const EVENT_IDS = attachment.eventIds ? attachment.eventIds : false;

    if (EVENT_IDS) {
      for (const id of EVENT_IDS) {
        try {
          await onDeleteEventAttachment(attachment, id);
        } catch (e) {
          console.error(e);
        } finally {
          // this is temporary solution (fully behaviour is not setup - expectations could change)
          window.location.reload();
          return 'success';
        }
      }
    } else {
      onDeleteEventAttachment(attachment);
    }

    return null;
  };

  const onCreateEventAttachments = async() => {
    let attachmentIds = [];
    const files = await uploadFiles();
    if (files) {
      attachmentIds = files.map((attachment) => attachment.id);
    }

    try {
      await createEventAttachment({
        variables: {
          eventId: Number(eventId),
          attachmentIds,
        },
      });
    } catch (e) {
      console.log('error creating event detail attachments', e);
    } finally {
      setSelectedFiles([]);
    }
    
    return null;
  };

  useEffect(() => {
    if (params.id) {
      setEventId(params.id);
    }
  }, [ params ]);

  return {
    isModalOpened: state.isModalOpened,
    setIsModalOpen,
    view: state.view,
    setView,
    selectedFilesState: state.selectedFiles,
    selectedFiles: getFilteredSelectedFiles(),
    setSelectedFiles,
    uploadedFiles: state.uploadedFiles,
    uploadedFilesIDs: state.uploadedFiles.map((attachment) => attachment.id),
    setUploadedFiles,
    handleFileChange,
    handleRemoveFile,
    selectedFilesTotalSize: selectedFilesTotalSize(),
    filter: state.filter,
    setFilter,
    uploadFiles,
    isUploading,
    isSelectedUploaded,
    avatarEditor,
    uploadAvatar,
    updateAvatar,
    isAvatarSuccess,
    setIsAvatarSuccess,
    getFileURL,
    fetchMoreAttachments: () => setOffset(offset + ATTACHMENT_LIMIT_LIST),
    getType,
    onDeleteEventAttachment,
    onDeleteAttachment,
    onCreateEventAttachments,
    isDeleteIntent: state.isDeleteIntent,
    setIsDeleteIntent,
    isEventDetailIntent: state.isEventDetailIntent,
    setIsEventDetailIntent,
    loadAttachments,
    attachments: getFilteredAttachments(),
    setAttachments,
    isLast,
    loadingAttachments,
    allAttachments: loadedAttachments,
    loadAttachmentsEvent,
    attachmentsEvent: getFilteredAttachmentsEvent(),
    allAttachmentsEvent: attachmentsEvent?.eventAttachmentsByEventId,
  };
};

export default useAttachments;
