import React, { useEffect, useState } from 'react';
import { Circle, ContentWrapper, LoaderWrapper, pulse, TextWrapper, Top } from './styles';
import CountUp from 'react-countup';
import { Typography, Button, Box, Paper } from '@mechis/elements';
import { useParams, useNavigate } from 'react-router-dom';
import { usePaymentResultPreviewQuery } from '@state/mechis-backend/generated/schema';
import { useLocalStorage } from 'react-use';
import { USER_ID } from '@configurations/constants/app';
import Loader from '@mechis/blocks/Loader';
import { useTranslation } from 'react-i18next';
import { PaletteColor, useTheme } from '@mui/material';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

type OptionType = {
  color: string
  icon: React.ElementType
  description: string[]
};

const options = {
  success: {
    color: 'secondary',
    icon: CheckIcon,
    description: [ 'description1' ],
  },
  failed: {
    color: 'error',
    icon: CloseIcon,
    description: [ 'description1' ],
  },
  pending: {
    color: 'warning',
    icon: HourglassTopIcon,
    description: [ 'description1', 'description2' ],
  },
};

const Payment = () => {
  const { status } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ userId ] = useLocalStorage(USER_ID, '');
  const [ option, setOption ] = useState<OptionType | undefined>(undefined);
  const { data, loading, error } = usePaymentResultPreviewQuery({
    variables: {
      userId: Number(userId),
    },
  });

  useEffect(() => {
    const isSet = status === 'success' || status === 'failed' || status === 'pending' ? true : false;
    if (!isSet) {
      navigate('/');
    } else {
      setOption(options[status as keyof typeof options]);
    }
  }, [ status ]);

  if (loading || !option) {
    return <LoaderWrapper><Loader /></LoaderWrapper>;
  }

  if (error) {
    throw new Error('Unable to get credit data');
  }

  return (
    <>
      <Top />
      <ContentWrapper>
        <Paper>
          <>
            <Circle bgcolor={(theme.palette[option.color as keyof typeof theme.palette] as PaletteColor).main}>
              <option.icon
                fontSize="inherit"
                color="inherit"
                style={{ animation: `${pulse} 2s infinite` }}
              />
            </Circle>
            <TextWrapper>
              <Typography variant="h1" color={(theme.palette[option.color as keyof typeof theme.palette] as PaletteColor).main}>
                {t(`paymentResult.${status}.header`)}
              </Typography>
              <Typography py={1} variant="h4">
                {t(`paymentResult.${status}.subheader`)}
              </Typography>
              <CountUp
                start={0}
                end={data?.user?.creditBalance ?? 0}
                duration={1.5}
                prefix={t('paymentResult.countUp.prefix')}
                separator=" "
                suffix={t('paymentResult.countUp.suffix')}
              />
              <Box pt={1}>
                {option.description.map((description, index) => (
                  <Typography key={index} variant="body1">
                    {t(`paymentResult.${status}.${description}`)}
                  </Typography>
                ))}
              </Box>
              <Typography pt={1} variant="body2">
                <a href="https:www.mechis.cz/">Mechis.cz</a>
              </Typography>
              <Typography variant="body2">
                {t('paymentResult.emailInfo')}
              </Typography>
            </TextWrapper>
          </>
          <Box pt={2} pb={6} display={'flex'} justifyContent={'center'}>
            {(status === 'success' || status === 'pending') && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate('/app/mechis')}
              >
                {t('paymentResult.continue')}
              </Button>
            )}
            {status === 'failed' && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate('/app/mechis')}
              >
                {t('paymentResult.back')}
              </Button>
            )}
          </Box>
        </Paper>
      
      </ContentWrapper>
    </>
  );
};

export default Payment;
