import React, { FC, useState, useEffect } from 'react';
import {
  IAttachmentsWidgetItemProps,
  useAttachments,
} from '@mechis/sections/Attachments';
import { Avatar, Box } from '@mechis/elements';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import BorderAllIcon from '@mui/icons-material/BorderAll';

const AttachmentsWidgetItem: FC<IAttachmentsWidgetItemProps> = ({
  src,
  alt,
  type,
  name,
  onClick,
}) => {
  const { getType, getFileURL } = useAttachments();
  const [ source, setSource ] = useState<string | null>(null);

  useEffect(() => {
    const fetchAvatarUrl = async () => {
      try {
        const url = await getFileURL(src);
        setSource(url);
      } catch (error) {
        console.log('Error fetching avatar URL:', error);
        setSource(null);
      }
    };

    fetchAvatarUrl();
  }, [ src ]);

  const onClickAvatar = () => {
    if (getType(type) === 'doc' && source) {
      window.open(source, '_blank');
    }
    if (getType(type) === 'image' && onClick) {
      onClick();
    }
  };

  const getAvatar = () => {
    switch (type) {
      case 'application/pdf':
        return <PictureAsPdfIcon color="error" fontSize="large" />;
      case 'application/msword':
        return <TextSnippetIcon color="info" fontSize="large" />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <TextSnippetIcon color="info" fontSize="large" />;
      case 'text/plain':
        return <TextSnippetIcon color="disabled" fontSize="large" />;
      case 'application/vnd.ms-excel':
        return <BorderAllIcon color="success" fontSize="large" />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <BorderAllIcon color="success" fontSize="large" />;
      case 'text/csv':
        return <BorderAllIcon color="success" fontSize="large" />;
      default:
        return null;
    }
  };

  return (
    <Box
      position="relative"
      display="inline-block"
      width="120px"
      height="120px"
      onClick={onClickAvatar}
    >
      {/* Avatar */}
      <Avatar
        variant="rounded"
        src={source || undefined}
        alt={alt}
        sx={{
          border: '1px solid lightgrey',
          backgroundColor: '#bdbdbd1f',
          width: '120px',
          height: '120px',
        }}
      >
        {getAvatar()}
      </Avatar>

      {/* Overlay Box */}
      <Box
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        bgcolor="rgba(0, 0, 0, 0.6)"
        color="#fff"
        textAlign="center"
        padding="5px"
        fontSize="12px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        sx={{
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        {name ? name.split(' ')[0] : ''}
      </Box>
    </Box>
  );
};

export default AttachmentsWidgetItem;
