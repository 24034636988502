import React, { FC, useState, useEffect } from 'react';
import { useAttachments, AttachmentsControl } from '@mechis/sections/Attachments';
import {
  Box,
  Typography,
  Button,
  Slider,
  IconButton,
  Stack,
  CircularProgress,
} from '@mechis/elements';
import AvatarEditor from 'react-avatar-editor';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

interface IAttachmentsAvatar {
  isUpdateAvatar: boolean
}

const AttachmentsAvatar: FC<IAttachmentsAvatar> = ({ isUpdateAvatar }) => {
  const {
    selectedFiles,
    isUploading,
    avatarEditor,
    uploadAvatar,
    updateAvatar,
    isAvatarSuccess,
    setIsAvatarSuccess,
    setSelectedFiles,
  } = useAttachments();
  const isSmallScreen = useMediaQuery('(max-width: 400px)');
  const [ scale, setScale ] = useState(1.2);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleScaleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setScale(newValue);
    }
  };

  useEffect(() => {
    if (isAvatarSuccess) {
      setSelectedFiles([]);
      setIsAvatarSuccess(false);

      if (pathname.startsWith('/app/new-tech/')) {
        const nextStep = pathname.slice(0, -1) + '1';
        navigate(nextStep);
      } else {
        navigate(-1);
      }
    }
  }, [ isAvatarSuccess ]);

  return (
    <div>
      {selectedFiles.length === 0 && (
        <Box
          p={2}
          sx={{
            border: '2px dashed lightgrey',
            width: '250px',
            height: '250px',
            borderRadius: '12px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <AttachmentsControl variant="avatar">
            <Box sx={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', mt: 6 }}>
              <Typography
                variant="body2"
                sx={{ opacity: 0.8 }}
              >
                <small>Prosím vyberte fotografii</small>
              </Typography>
              <IconButton
                size="large"
                sx={{
                  border: '1px dashed lightgrey',
                  marginTop: '16px',
                  pointerEvents: 'none',
                }}
              >
                <DriveFolderUploadIcon sx={{ fontSize: '32px' }} />
              </IconButton>
            </Box>
          </AttachmentsControl>
        </Box>
      )}
      {selectedFiles.map((file, index) => (
        <Box key={index} textAlign="center">
          <AvatarEditor 
            ref={avatarEditor}
            // @ts-ignore
            image={file.preview}
            width={isSmallScreen ? 200 : 250}
            height={isSmallScreen ? 200 : 250}
            border={isSmallScreen ? 20 : 40}
            color={[ 0, 0, 0, 0.4 ]}
            scale={scale}
            rotate={0}
            //borderRadius={40}
            style={{
              marginTop: '8px',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
          />
          <Stack direction="row" justifyContent="space-between"alignItems="center" px={4} mt={1}>
            <AttachmentsControl variant="avatar">
              <Button variant="outlined" size="small" color="secondary" sx={{ pointerEvents: 'none' }}>Vybrat jinou</Button>
            </AttachmentsControl>
            <IconButton sx={{ border: '1px dashed lightgrey' }}>
              <DeleteIcon onClick={() => setSelectedFiles([])} />
            </IconButton>
          </Stack>
          <Stack alignItems="center" spacing={2}>
            <Typography variant="h4">
              {scale}x
            </Typography>
            <Slider
              value={scale}
              min={1}
              max={5}
              step={0.1}
              onChange={handleScaleChange}
              aria-labelledby="zoom-slider"
              style={{ width: '300px', margin: '0px auto' }}
            />
            <Button
              onClick={isUpdateAvatar ? updateAvatar : uploadAvatar}
              variant="contained"
              color="secondary"
              size={isSmallScreen ? 'small' : 'medium'}
              disabled={isUploading}
            >
              {isUploading ? <CircularProgress size={22} /> : 'Nahrát avatar'}
            </Button>
          </Stack>
        </Box>
      ))}
    </div>
  );
};

export default AttachmentsAvatar;
