import React, { useState, useEffect } from 'react';
import { useAttachments, AttachmentAllowedType } from '@mechis/sections/Attachments';
import { useNavigate } from 'react-router-dom';
import useTech from '@hooks/useTech';
import { Maybe } from '@state/mechis-backend/generated/schema';
import {
  AttachmentsConfirmDeleteModal,
  AttachmentsGridItem,
  AttachmentsGridLoaderItem,
  AttachmentsListItem,
  AttachmentsListLoaderItem,
  AttachmentsLightboxItem,
  AttachmentsMultipleEventsModal,
} from '@mechis/sections/Attachments/components';
import {
  List,
  Grid,
  Box,
} from '@mechis/elements';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '@mechis/blocks/Loader';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const AttachmentsFetched = () => {
  const {
    isLast,
    view,
    attachments,
    loadingAttachments,
    setAttachments,
    fetchMoreAttachments,
    loadAttachments,
    allAttachments,
    setIsDeleteIntent,
    setIsEventDetailIntent,
  } = useAttachments();

  useEffect(() => {
    setAttachments([]);
    loadAttachments();

    return () => {
      setAttachments([]);
    };
  }, []);

  const navigate = useNavigate();
  const { techId } = useTech();

  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);

  const imageSlides = (attachments ?? [])
    .filter((attachment) => attachment?.mimeType?.startsWith('image/'))
    .map((attachment) => ({
      src: attachment.storageName || '',
      width: 1200,
      height: 800,
    }));

  const onClick = (storageName: string) => {
    if (attachments) {
      setClickedImageIndex(
        attachments
          .filter((attachment) => attachment?.mimeType?.startsWith('image/'))
          .findIndex((attachment) => attachment.storageName === storageName)
      );
      setLightBoxOpened(true);
    }
  };

  const onDetail = (ids?: Maybe<any[]>) => {
    if (ids && ids.length) {
      if (ids.length > 1) {
        setIsEventDetailIntent(ids);
      } else {
        navigate(`/app/${techId}/tech-detail/record/${ids[0]}`);
      }
    }
    return null;
  };

  if (!loadingAttachments && allAttachments?.attachmentsByDeviceAndFiltersAndTags?.attachment?.length === 0) {
    return (
      <Box textAlign="center" mt={2}>
        <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy pro danou techniku</em>
      </Box>
    );
  }

  if (!loadingAttachments && attachments?.length === 0) {
    return (
      <Box textAlign="center" mt={2}>
        <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy tohoto typu</em>
      </Box>
    );
  }

  if (view === 'list') {
    if (loadingAttachments) {
      return (
        <List>
          {[ ...Array(10) ].map((_, index) => (
            <AttachmentsListLoaderItem key={index} />
          ))}
        </List>
      );
    }

    return (
      <>
        <AttachmentsConfirmDeleteModal />
        <AttachmentsMultipleEventsModal />
        <InfiniteScroll
          dataLength={attachments?.length || 0}
          next={fetchMoreAttachments}
          hasMore={!isLast}
          loader={<Loader size={20} />}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDiv"
        >
          <List>
            {attachments?.map((file, index) => {
              return (
                <AttachmentsListItem
                  key={index}
                  variant="fetched"
                  src={file.storageName || ''}
                  alt={file.originalName || ''}
                  type={file.mimeType as AttachmentAllowedType}
                  name={file.originalName || ''}
                  onClick={() => onClick(file.storageName || '')}
                  onDelete={() => setIsDeleteIntent(file)}
                  onDetail={() => onDetail(file.eventIds)}
                />
              );
            })}
          </List>
          <Lightbox
            open={lightBoxOpened}
            close={() => setLightBoxOpened(false)}
            index={clickedImageIndex}
            slides={imageSlides}
            render={{ slide: ({ slide }) => <AttachmentsLightboxItem storageName={slide?.src || ''} /> }}
            plugins={[ Zoom ]}
            zoom={{ maxZoomPixelRatio: 3, scrollToZoom: true }}
          />
        </InfiniteScroll>
      </>
    );
  }

  if (view === 'grid') {
    if (loadingAttachments) {
      return (
        <Grid container spacing={2} mt={2}>
          {[ ...Array(15) ].map((_, index) => (
            <AttachmentsGridLoaderItem key={index} />
          ))}
        </Grid>
      );
    }

    return (
      <Box mt={2}>
        <InfiniteScroll
          dataLength={attachments?.length || 0}
          next={fetchMoreAttachments}
          hasMore={!isLast}
          loader={<Loader size={20} />}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDiv"
        >
          <Grid container spacing={2}>
            {attachments?.map((file, index) => {
              return (
                <AttachmentsGridItem
                  key={index}
                  variant="fetched"
                  src={file.storageName || ''}
                  alt={file.originalName || ''}
                  type={file.mimeType as AttachmentAllowedType}
                  name={file.originalName || ''}
                  onClick={() => onClick(file.storageName || '')}
                />
              );
            })}
            <Lightbox
              open={lightBoxOpened}
              close={() => setLightBoxOpened(false)}
              index={clickedImageIndex}
              slides={imageSlides}
              render={{ slide: ({ slide }) => <AttachmentsLightboxItem storageName={slide?.src || ''} /> }}
              plugins={[ Zoom ]}
              zoom={{ maxZoomPixelRatio: 3, scrollToZoom: true }}
            />
          </Grid>
        </InfiniteScroll>
      </Box>
    );
  }

  return null;
};

export default AttachmentsFetched;

